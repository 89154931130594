class AjaxPosts {
	constructor(details) {
		this.request = 'paged=1'
		if(!details.target) return

		this.postTarget = details.target
		this.postLoadingSpinner = details.loadingSpinner
		this.postAjaxUrl = details.url
		this.postLoading = details.postLoading

		this.page = 1
		
		this.filters = details.filters
		this.filterArray = null
		this.restriction = null
		this.searchTerm = (window.sessionStorage.getItem('searchTerm') != null) ? window.sessionStorage.getItem('searchTerm') : null

		if(this.filters) {
			this.value = this.filters
		}
		
		this.http = new XMLHttpRequest()

		this.init()
	}

	displayPosts() {
		
		this.postTarget.classList.add('transition')
		this.postLoadingSpinner.classList.add('loading')

		let t = this
		this.http.onreadystatechange = function() {
			if(this.readyState == 4) {

				if(t.http.responseText === '') {
					t.postTarget.parentElement.classList.add('no_results')
				} else {
					t.postTarget.parentElement.classList.remove('no_results')
				}

				t.postTarget.innerHTML = t.http.responseText
				setTimeout(function() {
					t.postLoadingSpinner.classList.remove('loading')
					t.postTarget.classList.remove('transition')
					t.postTarget.style = 'min-height: 0px'
				}, 100)
				setTimeout(function() {
					let height = t.postTarget.offsetHeight
					t.postTarget.style = 'min-height: ' + height + 'px'
					
					const pageLinks = document.querySelectorAll('.page_link span')
					pageLinks.forEach( (el) => {
						el.addEventListener('click', (e) => {
							let pageNo = el.parentElement.dataset.page
							t.updatePage(pageNo)
						})
					})
				}, 500)
			}
		}
		this.http.open('POST', this.postAjaxUrl, true)
		this.http.setRequestHeader('Content-type', 'application/x-www-form-urlencoded')
		this.http.send(this.request)

		
		
	}

	updatePosts(scroll = true) {
		this.request = 'paged=' + this.page
		if(this.categoryValue != null) this.request += '&categories=' + this.categoryValue
		if(this.restriction != null) this.request += '&restriction=' + this.restriction
		if(this.searchTerm != null) this.request += '&search=' + this.searchTerm
		this.displayPosts()
		//if(scroll) {
		//	setTimeout( () => {
		//		scrollToHash('#' + this.postTarget.id)
		//	}, 350)
		//}
	}

	updateFilters(scroll = true, clicked = 'desktop') {
		this.page = 1
		this.filterArray = []
		
		this.categoryValue = this.filters.value

		if(this.categoryValue != '') {
			this.filters.classList.add('hasValue')		
		} else {
			this.filters.classList.remove('hasValue')	
		}
		this.updatePosts(scroll)
	}

	clearFilters() {
		this.filters.value = ''
	}

	updatePage(newPage) {
		this.page = newPage
		this.updatePosts()
	}

	updateSearch(searchTerm) {
		this.page = 1
		this.searchTerm = searchTerm
		this.updatePosts()
	}
	
	init() {
		if(!this.filters && !this.filtersMobile) this.updatePosts(false)
		
		if(this.filters || this.filtersMobile) {
			setTimeout( () => {
				this.updateFilters(false)
			}, 300)
		}

		if(this.filters) {
			this.filters.addEventListener('change', () => {
				this.updateFilters(true, 'desktop')
			})
		}

	}
}



const partnerGrid = document.querySelector('#partnersGrid'),
	loadingSpinner = document.querySelector('#loading_spinner'),
	productFilters = document.querySelector('#catSelect'),
	searchTerm = document.querySelector('#partner_search')

const partnerDetails = {
	'target' : partnerGrid,
	'loadingSpinner' : loadingSpinner,
	'url' : '../../wp-content/themes/mercury/ajax/partners.php',
	'filters' : productFilters,
	'searchTerm' : searchTerm
}

const partners = new AjaxPosts(partnerDetails)

function pageChange(newPage) {
	partners.updatePage(newPage)
}	

function searchUpdate(search) {
	partners.updateSearch(search)
}

function toggleFiltersMobile() {
	const filterContainer = document.querySelector('.filter_container.mobile')

	filterContainer.classList.toggle('active')
	document.body.classList.toggle('noscroll')
}

const clearFilterBtn = document.querySelector('#clearFilters')
if(clearFilterBtn) clearFilterBtn.addEventListener('click', clearFilters)

const clearSearchBtns = document.querySelectorAll('.clearSearch')
if(clearSearchBtns) {
	clearSearchBtns.forEach( (clearSearchBtn) => {
		clearSearchBtn.addEventListener('click', clearSearchTerm)
	})
}

const clearFiltersBtns = document.querySelectorAll('.clearFilters')
if(clearFiltersBtns) {
	clearFiltersBtns.forEach( (clearFiltersBtn) => {
		clearFiltersBtn.addEventListener('click', clearFilterTerms)
	})
}

function clearFilterTerms() {
	partners.clearFilters()
	partners.updateFilters()
}

function clearSearchTerm() {
	clearSearch()
	partners.updateSearch()
}

function clearFilters() {
	clearSearch()
	partners.clearFilters()
	partners.updateSearch()
	partners.updateFilters()
}

function clearSearch() {
	window.sessionStorage.setItem('searchTerm', '')
	const searchButtons = document.querySelectorAll('.searchButton')
	searchButtons.forEach( (sb) => {
		let parent = sb.parentElement,
			input = parent.querySelector('input')
		parent.classList.remove('hasValue')
		input.value = ''
		
	})	
}

const searchButtons = document.querySelectorAll('.searchButton')
if(searchButtons) {
	searchButtons.forEach( (sb) => {
		let input = sb.parentElement.querySelector('input')
		
		let term  = (window.sessionStorage.getItem('searchTerm') != null) ? window.sessionStorage.getItem('searchTerm') : null

		if(term != null) {
			let parent = input.parentElement
			input.value = term
			parent.classList.add('hasValue')
		}

		sb.addEventListener('click', (e) => {
			e.preventDefault()
			searchTermProcess(input)
		})
		input.addEventListener('keypress', (e) => {
			if(e.key === 'Enter') {
				e.preventDefault()
				searchTermProcess(input)
			}
		})
	})
	
}



const mobileFilters = document.querySelectorAll('.mobile-filter')
mobileFilters.forEach( (e) => {
	e.addEventListener('click', () => {
		toggleFiltersMobile()
	})
})

const recipeGrid = document.querySelector('#recipeGrid')

if(recipeGrid) {
	const recipeDetails = {
		'target' : recipeGrid,
		'loadingSpinner' : loadingSpinner,
		'url' : '../wp-content/themes/alamgeer/ajax/recipes.php',
	}
	const recipes = new AjaxPosts(recipeDetails)

	function pageChangeRecipe(newPage) {
		recipes.updatePage(newPage)
	}	
}

function searchTermProcess(input) {
	let parent = input.parentElement
	let searchTerm = (input.value != '') ? input.value : ''
	if(searchTerm !== '') {
		parent.classList.add('hasValue')
	} else {
		parent.classList.remove('hasValue')
	}
	window.sessionStorage.setItem('searchTerm', searchTerm)
	partners.updateSearch(searchTerm)
	if(input.id === 'product_search_mobile') {
		toggleFiltersMobile()
	}

	searchButtons.forEach( (sb) => {
		let input = sb.parentElement.querySelector('input')
		input.value = searchTerm
	})
}