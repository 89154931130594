jQuery('.microsoft_logos').slick({
	infinite: true,
	slidesToShow: 1,
	slidesToScroll: 1,
	dots: false,
	arrows : false,
	autoplay : true,
	autoplaySpeed: 2500,
	pauseOnHover: false,
	pauseOnFocus: false,
});

jQuery('.logo_carousel').slick({
	infinite: true,
	slidesToShow: 4,
	slidesToScroll: 1,
	dots:false,
	arrows : false,
	autoplay : true,
	autoPlaySpeed : 800,
	pauseOnHover: false,
	pauseOnFocus: false,
	swipeToSlide: true,
	draggable: true,
	responsive: [
		{
			breakpoint: 1339,
			settings: {
				slidesToShow: 3,
				slidesToScroll: 1,
			}
		}
	]
});

jQuery('.partner_logos').slick({
	infinite: true,
	slidesToShow: 4,
	slidesToScroll: 1,
	dots:false,
	arrows : false,
	autoplay : true,
	autoPlaySpeed : 800,
	pauseOnHover: false,
	pauseOnFocus: false,
	swipeToSlide: true,
	draggable: true,
	responsive: [
		{
			breakpoint: 1339,
			settings: {
				slidesToShow: 3,
				slidesToScroll: 1,
			}
		}
	]
});

jQuery('.product_partners').slick({
	infinite: true,
	slidesToShow: 4,
	slidesToScroll: 1,
	dots: false,
	arrows : false,
	autoplay : true,
	autoPlaySpeed : 500,
	pauseOnHover: false,
	pauseOnFocus: false,
	swipeToSlide: true,
	draggable: true,
	responsive: [
		{
			breakpoint: 747,
			settings: {
				slidesToShow: 3
			}
		}
	]
});

jQuery('.teams_slider').slick({
	infinite: true,
	slidesToShow: 4,
	dots:false,
	arrows : true,
	autoplay : false,
	pauseOnHover: false,
	pauseOnFocus: false,
	swipeToSlide: true,
	draggable: false,
	prevArrow:"<button class='slick-prev slick-arrow' aria-label='Prev' type='button' style='display: block;'><i class='far fa-chevron-left'></i></button>",
    nextArrow:"<button class='slick-next slick-arrow' aria-label='Next' type='button' style='display: block;'><i class='far fa-chevron-right'></i></button>",
	responsive: [
		{
			breakpoint: 1439,
			settings: {
				slidesToShow: 3,
			}
		},
		{
			breakpoint: 767,
			settings: {
				slidesToShow: 2,
				infinite: true
			}
		},
		{
			breakpoint: 450,
			settings: {
				slidesToShow: 1,
				infinite: true
			}
		},
	]
});

jQuery('.testimonials_slider').slick({
	infinite: true,
	slidesToShow: 1,
	slidesToScroll: 1,
	dots: true,
	arrows : false,
	autoplay : true,
	autoplaySpeed: 10000,
	pauseOnHover: false,
	pauseOnFocus: false,
	swipeToSlide: true,
	asNavFor: '.testimonials_logo-slider'
});

jQuery('.testimonials_logo-slider').slick({
	infinite: true,
	slidesToShow: 1,
	slidesToScroll: 1,
	dots: false,
	arrows : false,
	autoplay : true,
	pauseOnHover: false,
	pauseOnFocus: false,
	swipeToSlide: true,
	asNavFor: '.testimonials_slider'
});

jQuery('.features_slider').slick({
	infinite: true,
	slidesToShow: 1,
	slidesToScroll: 1,
	dots: false,
	arrows : true,
	autoplay : false,
	autoPlaySpeed : 6000,
	pauseOnHover: true,
	pauseOnFocus: false,
	swipeToSlide: true,
	draggable: true,
	prevArrow:"<button class='slick-prev slick-arrow' aria-label='Prev' type='button' style='display: block;'><i class='far fa-chevron-left'></i></button>",
    nextArrow:"<button class='slick-next slick-arrow' aria-label='Next' type='button' style='display: block;'><i class='far fa-chevron-right'></i></button>"
});