// =========
// If on homepage, animate header size and background
// =========
function checkHeaderClass() {
	var top = jQuery(window).scrollTop();
	
	if(jQuery('body.home').length > 0) {
		if(top > 100) {
			jQuery('.header').addClass('background');
			jQuery('.header').removeClass('no_background');
		} else {
			jQuery('.header').addClass('no_background');
			jQuery('.header').removeClass('background');
		}
	}
};

jQuery(document).ready(function () {
	// =========
	// Used for mobile dropdown menu
	// =========
	var acc = document.getElementsByClassName("mobile-dropdown");
	var i;
	for (i = 0; i < acc.length; i++) {
		acc[i].addEventListener("click", function() {
			this.classList.toggle("opened");
			var panel = this.nextElementSibling;
			if (panel.style.maxHeight) {
				panel.style.maxHeight = null;
				panel.classList.toggle("opened");
			} else {
				panel.style.maxHeight = panel.scrollHeight + "px";
				panel.classList.toggle("opened");
			}
		}, {
			passive: false
		});
	};

	// =========
	// When the document is ready, if on homepage,
	// animate the landing screen overlay and draw in box
	// =========
	if(jQuery('#home_landing-video').length > 0) {
		if(window.innerWidth > 767) {
			var video = jQuery('#home_landing-video');
			var source = document.createElement('source');

			source.setAttribute('src', '/wp-content/uploads/video.mp4');
			video.append(source);
			video.load();
		}
	}

	// =========
	// When the document is ready, if on homepage, animate header size and background
	// =========
	checkHeaderClass();
});

jQuery(window).on('resize', function(){
	// =========
	// When window is resized, if on homepage, animate header size and background
	// =========
	checkHeaderClass();
})


var alreadyAnimated = false;

window.addEventListener('scroll', function() {
	// =========
	// If on homepage, animate header size and background
	// =========
	checkHeaderClass();

	// =========
	// Animate the stat numbers on about page only once when they are in view
	// =========
	if(jQuery('.stat-number').length > 0) {
		if(isScrolledIntoView('.stat-number') && alreadyAnimated == false) {
			alreadyAnimated = true;
			animateNumbers();
		}
	}
}, {passive: true});

jQuery( window ).load(function(){
	// =========
	// Run the isotope function on load, filters automatically
	// depending on if there's an active filter class
	// =========

	if(jQuery('.filter').length > 0) {
		if(jQuery('.filter.active').length > 0) {
			filterValue = jQuery('.filter.active').data('filter');
		} else if (window.location.hash == "#all"){
			filterValue = '*';
			jQuery('.filter[data-filter="*"]').addClass('active');
			if(jQuery('.filter_grid').hasClass('hidden')) {
				jQuery('.filter_grid').removeClass('hidden');
			}

			jQuery('html, body').animate({
				scrollTop: jQuery('.filter[data-filter="*"]').offset().top - jQuery('.fixed_start').outerHeight(true)
			}, 600);

		} else {
			filterValue = '*';
		}

		filterValue = '*';

		var $grid = jQuery('.will_filter');
		$grid.isotope({
			itemSelector: '.single_filter',
			initLayout: false,
			filter: filterValue,
			layoutMode: 'fitRows',
			masonry: {
				columnWidth: '.single_filter'
			}
		});
	}

	// =========
	// Scroll down to form afer submission to see success message
	// =========
	var $af_success = jQuery('.af-success');
	if ($af_success.length > 0) {
		var scrollTop = $af_success.offset().top - $af_success.outerHeight(true) - jQuery('.fixed_start').outerHeight(true);
		document.body.scrollTop = scrollTop
	    document.documentElement.scrollTop = scrollTop;
	}
});

// =========
// DO NOT DELETE! Makes filter grid work for some reason.
// =========
var $grid = jQuery('.will_filter').isotope();

// =========
// Change filter category on filter button click
// =========
jQuery('.filter-button-group').on( 'click', 'button', function() {
	if(jQuery('.filter_grid').hasClass('hidden')) {
		jQuery('.filter_grid').removeClass('hidden');
	}
	jQuery('.filter-button-group button').not(this).removeClass('active');
	jQuery(this).addClass('active');
	var filterValue = jQuery(this).attr('data-filter');
	$grid.isotope({ filter: filterValue });

	if(jQuery('.single_filter.single_partner_archive').length > 0 && jQuery('.single_filter.single_partner_archive.active').length > 0) {
		jQuery(".single_filter.single_partner_archive").removeClass('hidden');
		jQuery('.single_filter.single_partner_archive.active').removeClass('active');
		jQuery('.single_filter.single_partner_archive').find('.toggle_title').removeClass("active");
		jQuery('.single_filter.single_partner_archive').find('.toggle_inner').slideUp(300);

		setTimeout(function() {
			$grid.isotope('layout');
		}, 320);
	}
});

// =========
// Block unwanted email addresses
// =========
//jQuery('.acf-input-wrap input[type="email"]').on('change', function() {
//	var email = jQuery(this).val();
//	var split_email = email.split('@');
//	var blockedEmails = ["hotmail.ca","hotmail.ch","hotmail.co","hotmail.co.il","hotmail.co.jp","hotmail.co.nz","hotmail.co.uk","hotmail.co.za","hotmail.com","hotmail.com.au","hotmail.com.br","hotmail.com.tr","hotmail.de","hotmail.es","hotmail.fi","hotmail.fr","hotmail.it","hotmail.kg","hotmail.kz","hotmail.nl","hotmail.ru","gmail.com","gmail.com.br","gmail.ru","icloud.com","me.com","mobileme.com","outlook.at","outlook.be","outlook.cl","outlook.co.id","outlook.co.il","outlook.co.nz","outlook.co.th","outlook.com","outlook.com.au","outlook.com.br","outlook.com.gr","outlook.com.pe","outlook.com.tr","outlook.com.vn","outlook.cz","outlook.de","outlook.dk","outlook.es","outlook.fr","outlook.hu","outlook.ie","outlook.in","outlook.it","outlook.jp","outlook.kr","outlook.lv","outlook.my","outlook.nl","outlook.ph","outlook.ph","outlook.sa","outlook.sg","outlook.sk","aol.com","sky.com","tiscali.at","tiscali.be","tiscali.co.uk","tiscali.it","tiscali.lu","tiscali.se"];

//	var error = '<div class="acf-notice -error acf-error-message js_error"><p>Sorry, we do not accept submissions from ' + split_email[1] + ' emails</p></div>';
//	jQuery('.af-field-type-email .af-input .acf-notice').remove();

//	if(blockedEmails.includes(split_email[1])) {
//		jQuery('.af-field-type-email .af-input .acf-notice').remove();
//		jQuery('.af-field-type-email .af-input').prepend(error);
//		jQuery('.af-submit-button').prop('disabled', true);
//	} else {
//		jQuery('.af-submit-button').prop('disabled', false);
//	}
//});

// =========
// Show active accordion and hide the rest
// =========
if(jQuery(".toggle .toggle_title").hasClass('active') ){
	jQuery(".toggle .toggle_title.active").closest('.toggle').find('.toggle_inner').show();
}

jQuery(".toggle .toggle_title").click(function() {
	var el = jQuery(this);

	if(jQuery('.single_partner_archive').length == 0) {
		if( jQuery(this).hasClass('active') ){
			jQuery(this).removeClass("active").closest('.toggle').find('.toggle_inner').slideUp(300);
		} else {
			jQuery(".toggle .toggle_title.active").closest('.toggle').find('.toggle_inner').slideUp(300);
			jQuery(".toggle .toggle_title.active").removeClass('active');
			jQuery(this).addClass("active").closest('.toggle').find('.toggle_inner').slideDown(300);
		}

		setTimeout(function() {
			jQuery('html, body').animate({
				scrollTop: el.offset().top - jQuery('.fixed_start').outerHeight(true)
			}, 600);
		}, 300);
	}
});


// =========
// Go to post archive on form change
// =========
jQuery('#monthSelect.newsArchive').on('change', function() {
	var url = this.value;
	window.location = url;
});

// =========
// Submits form on Events page to change archive date
// =========
jQuery('.eventArchive').on('change', function() {
	jQuery('.eventArchiveForm').submit();
});

// =========
// Called when the user scrolls on the about page
// =========
function isScrolledIntoView(elem) {
    var docViewTop = jQuery(window).scrollTop();
    var docViewBottom = docViewTop + jQuery(window).height();

    var elemTop = jQuery(elem).offset().top;
    var elemBottom = elemTop + jQuery(elem).height();

    return ((elemBottom <= docViewBottom) && (elemTop >= docViewTop));
}

// =========
// Animate numbers on About page
// =========
function animateNumbers() {
	jQuery('.stat-number').each(function(index) {
	   var size = jQuery(this).text().split(".")[1] ? jQuery(this).text().split(".")[1].length : 0;
	   jQuery(this).prop('Counter', 0).animate({
	      Counter: jQuery(this).text()
	   }, {
	      duration: 2000,
	      step: function (now) {
	         jQuery(this).text(parseFloat(now).toFixed(size));
	      }
	   });
	});
}

// =========
// Hotspots on mobile
// =========
var timeout;
function hotspot(popid) {
	clearTimeout(timeout);
	jQuery('.pop_mobile').removeClass('reveal');
	var d = document.getElementById(popid);
	d.className += " reveal";

	timeout = setTimeout(function () {
		jQuery('.pop_mobile').removeClass('reveal');
	}, 3000)
}

// =========
// Partners Featherlight
// =========
// jQuery('.partner_read_more').each(function() {
// 	$content = jQuery(this).parents('.single_partner-inner').find('.popup_content');

// 	jQuery(this).featherlight($content, {variant: 'partner_popup'});
// });


//jQuery('a.applyBtn').featherlight({
//	persist: true,

//	afterContent: function () {
//		jQuery('.featherlight-content').find('a, input[type!="hidden"], select, textarea, iframe, button:not(.featherlight-close), iframe, [contentEditable=true]').each(function (index) {
//			if (index === 0) {
//				jQuery(this).prop('autofocus', true);
//			}
//			jQuery(this).prop('tabindex', 0);
//		});
//	}
//})

class customModal {
	constructor(btn, modal) {
		this.btn = btn
		this.modal = modal
		this.closeBtn = this.modal.querySelector('.close_btn')

		this.init()
	}

	toggleModal() {
		this.modal.classList.toggle('active')
		document.body.classList.toggle('modal_active')
	}

	bindAll() {
		["toggleModal"].forEach((fn) => (this[fn] = this[fn].bind(this)))
	}

	init() {
		this.bindAll()

		this.btn.addEventListener('click', this.toggleModal)
		this.closeBtn.addEventListener('click', this.toggleModal)
		console.log('here', this.btn)
	}

}

let applyTop = document.querySelector('.applyTop')
let applyBottom = document.querySelector('.applyBottom')
let modalTop = document.querySelector('.modal_container.top')
let modalBottom = document.querySelector('.modal_container.bottom')

if(applyTop) new customModal(applyTop, modalTop)
if(applyBottom) new customModal(applyBottom, modalBottom)